import React from 'react';

import Clock from '../../icons/promote/clock.svg';
import Cash from '../../icons/promote/commissi.svg';
import Business from '../../icons/promote/business.svg';
import Connection from '../../icons/promote/connection.svg';
import Question from '../../icons/promote/question.svg';
import Resources from '../../icons/promote/resources.svg';

const strings = {
  getPaidOn: 'Get paid on time',
  wevePaid: 'We’ve paid our commissions on time, every time, throughout our company history.',
  compCommission: 'Competitive commission',
  wePay:
    'We pay remarkable commissions because we understand that quality marketing is worth paying for.',
  allYourBusiness: 'All your business, one workplace',
  reachOut:
    'Reach out to hundreds of commerce partners. Manage relationships and enjoy transparent, aggregate reporting — all with one login.',
  makeReal: 'Make real connections',
  promotingOur:
    'Promoting our products enables you to interact with leading publishers to enhance your skills to grow your online business.',
  professional247: 'Professional, 24/7 support',
  gainAccess: 'Gain access to professional customer support in a snap.',
  creativeRes: 'Creative resources',
  aPortfolioOf: 'A portfolio of high-performing creative assets to help you promote the brands.',
};

export const businessList = [
  {
    title: strings.getPaidOn,
    description: strings.wevePaid,
    icon: <Clock />,
  },
  {
    title: strings.compCommission,
    description: strings.wePay,
    icon: <Cash />,
  },
  {
    title: strings.allYourBusiness,
    description: strings.reachOut,
    icon: <Business />,
  },
  {
    title: strings.makeReal,
    description: strings.promotingOur,
    icon: <Connection />,
  },
  {
    title: strings.professional247,
    description: strings.gainAccess,
    icon: <Question />,
  },
  {
    title: strings.creativeRes,
    description: strings.aPortfolioOf,
    icon: <Resources />,
  },
];

export const generateExpertiseList = (data) => {
  if (!data) {
    return null;
  }

  const titles = [
    'Travel',
    // 'Telecommunications',
    'Online Learning',
    // 'Technology and Services',
    'Retail',
  ];

  const list = titles.map((title, id) => {
    const imgName = `imageBg${id}`;
    return {
      title,
      img: data[imgName].childImageSharp.fluid,
    }
  });

  return list;
};
