import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import clsx from 'clsx';
import SEO from '../components/seo';
import Layout from '../components/layout';
import styles from '../styles/pages/promote.module.scss';
import { businessList, generateExpertiseList } from '../components/promote/config';
import BusinessListItem from '../components/busines-list-item';
import ShapeSvg from '../icons/promote/shape.svg';
import { pageLinks } from '../helpers/navigation';
import { isIbiInstances } from '../helpers/utils';

const query = graphql`
  query {
    image1: file(relativePath: { eq: "promote/image1.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "promote/image2.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "promote/image3.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageBg0: file(relativePath: { eq: "promote/image-bg0.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageBg1: file(relativePath: { eq: "promote/image-bg1.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageBg2: file(relativePath: { eq: "promote/image-bg2.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Promote = () => {
  const data = useStaticQuery(query);
  const expertiseList = generateExpertiseList(data);
  const text = isIbiInstances ? 'influencing' : 'affiliate marketing';

  return (
    <Layout fluid pageWrapperClassName={styles.page_wrapper}>
      <SEO title="Promote" />
      <div className={clsx(styles.blockWrapper, styles.topBlock)}>
        <div className={styles.textWrapper}>
          <h1>
            Be Relentless. <span className={styles.titleNextLine}>Be Unique.</span>
          </h1>
          <p>Your success is our success.</p>
        </div>
        <div className={styles.imageContainer}>
          <Img fluid={data?.image1.childImageSharp.fluid} />
        </div>
      </div>
      <div className={clsx(styles.blockWrapper, styles.reverse)}>
        <div className={styles.textWrapper}>
          <h3>Promote unparalleled products your audience will love.</h3>
          <p>
            {`Whether you are experienced in ${text} or just beginning your career, you
            need a partner solution that can handle your nuanced business requirements and evolve
            with the times.`}
          </p>
        </div>
        <div className={styles.imageContainer}>
          <Img fluid={data?.image2.childImageSharp.fluid} />
        </div>
      </div>
      <div className={styles.blockWrapper}>
        <div className={styles.textWrapper}>
          <h3>Take your business profits to new heights.</h3>
          <p>
            To stay ahead in today’s complex digital marketplace, you need a simplified means to
            change your approach. You need an integrated technology solution to discover, engage,
            and convert valuable customers and a partner with the experience to drive connections
            that create commerce. We empower you to take risks and explore new ways of
            thinking—because your success is our success.
          </p>
        </div>
        <div className={styles.imageContainer}>
          <Img fluid={data?.image3.childImageSharp.fluid} />
        </div>
      </div>
      <div className={styles.blockWrapper}>
        <h2>Get down to business with us</h2>
      </div>

      <div className={styles.listWrapper}>
        <div className={styles.businessList}>
          {businessList.slice(0, 3).map((item) => (
            <BusinessListItem key={item.title} title={item.title} description={item.description} icon={item.icon} />
          ))}
        </div>
        <div className={styles.businessList}>
          {businessList.slice(3, 6).map((item) => (
            <BusinessListItem key={item.title} title={item.title} description={item.description} icon={item.icon} />
          ))}
        </div>
      </div>
      <div className={styles.blockWrapper}>
        <div className={styles.expertiseText}>Our Expertise = Your Success</div>
      </div>
      <div className={styles.blockWrapper}>
        <div className={styles.expertiseWraper}>
          {expertiseList.map((item) => (
            <div key={item.title} className={styles.expertiseImageContainer}>
              <Img fluid={item.img} className={styles.expertiseImage} />
              <p className={styles.expertiseTextForImage}>{item.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.blockWrapper}>
        <div className={styles.bottomImage}>
          <ShapeSvg />
        </div>
        <div className={styles.signContainer}>
          <div className={styles.signTitle}>Connect with leading</div>
          <div className={styles.signTitle}>brands and make money.</div>
          <Link to={pageLinks.signUp2} className={styles.link}>
            Sign up today!
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Promote;
